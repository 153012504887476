<template>
  <d-input-group seamless>
    <d-input :placeholder="placeholder" ref="input"
      :value="value && value.title"
      @input.native.stop="$emit('input', $event.target.value)"
      @focus.native.stop="$emit('focus', $event)"
      @blur.native.stop="$emit('blur', $event)" />
    <d-input-group-addon v-if="qr" slot="append">
      <d-button squared theme="light" @click.prevent="clear"><i class="fas fa-qrcode"></i></d-button>
    </d-input-group-addon>
    <d-button v-else slot="append" theme="light" @click.prevent="clear">
      <i class="fas fa-times close-button"></i>
    </d-button>
  </d-input-group>
</template>

<script>
export default {
  name: 'suggest-input',
  props: ['value', 'placeholder', 'qr'],
  methods: {
    clear () {
      if (this.qr) {
        this.$emit('qr')
      } else {
        this.$emit('clear')
      }
    },
    focus () {
      const input = this.$refs.input
      if (input) {
        input.$el.focus()
      }
    }
  }
}
</script>

<style>
.vue-simple-suggest.designed .input-wrapper input {
  padding: 8px;
}
.vue-simple-suggest.designed .input-group-text:hover {
  color: #e53935;
}
.input-group-append .btn {
  border: none;
  background-color: #ffffff00;
}
.input-group-append::after {
  border-left: none !important;
}
i.close-button {
  color: #ccc;
}
i.close-button:hover {
  color: #212529;
}
</style>
