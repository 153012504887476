<template>
  <d-navbar-nav class="flex-row">
    <li class="nav-item dropdown" v-if="user">
      <a class="nav-link dropdown-toggle text-nowrap px-3" v-d-toggle.user-actions>
        <div class="user-avatar mr-2 d-md-inline-block">
          <i class="avatar fa fa-user-circle align-middle"></i>
        </div>
        <span class="d-none d-md-inline-block user">{{ user.name || user.userId }}</span>
      </a>
      <d-collapse id="user-actions" class="dropdown-menu dropdown-menu-small dropdown-menu-right">
        <d-dropdown-item @click.native="logout">
          <i class="fa fa-sign-out-alt"></i> Logout
        </d-dropdown-item>
      </d-collapse>
    </li>
    <d-modal id="login" v-if="showLogin" centered @close="showLogin = false">
      <login-dialog no-redirect @close="showLogin = false"/>
    </d-modal>
  </d-navbar-nav>
</template>

<script>
import LoginDialog from '@/components/LoginDialog'
import auth from '@/lib/auth'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      showLogin: false
    }
  },
  components: {
    LoginDialog
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ])
  },
  methods: {
    logout () {
      auth.logout()
    }
  }
}
</script>

<style>
  .nav-link:hover {
    cursor: pointer;
  }

  /* IE11 Navbar flex fix. */
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .navbar-nav {
      align-items: stretch !important;
      flex: 1 1 100%;
      flex-flow: row wrap;
    }

    .nav-item.notifications {
      margin-left: auto !important;
    }
  }

  .user {
    padding-top: 10px;
  }

  .avatar {
    font-size: 32px;
  }

  .modal-dialog {
    width: fit-content;
  }

  .modal-content {
    background-color: #ffffff00;
  }
</style>
