export default [{
  title: 'Dashboard',
  items: [{
    title: 'Alerts',
    to: '/alerts',
    htmlBefore: '<i class="fa fa-exclamation-triangle"></i>',
    rule: (can) => can('read', 'Alert')
  }, {
    title: 'Alert Log',
    to: '/log/alerts',
    htmlBefore: '<i class="fa fa-history"></i>',
    rule: (can) => can('read', 'LogAlert')
  }, {
    title: 'Floor Plans',
    to: '/floorplans',
    htmlBefore: '<i class="fa fa-map"></i>',
    rule: (can) => can('read', 'FloorPlan')
  }]
}, {
  title: 'Devices',
  items: [{
    title: 'Tags',
    to: '/tags',
    htmlBefore: '<i class="fa fa-rss"></i>',
    rule: (can) => can('manage', 'Tag')
  }, {
    title: 'Gateways',
    to: '/gateways',
    htmlBefore: '<i class="fa fa-microchip"></i>',
    rule: (can) => can('read', 'Gateway')
  }, {
    title: 'Consoles',
    to: '/consoles',
    htmlBefore: '<i class="fa fa-desktop"></i>',
    rule: (can) => can('read', 'Console')
  }, {
    title: 'Distribution Stations',
    to: '/distribution-stations',
    htmlBefore: '<i class="fa fa-id-badge"></i>',
    rule: (can) => can('read', 'DistributionStation')
  }]
}, {
  title: 'Settings',
  items: [{
    title: 'People',
    to: '/people',
    htmlBefore: '<i class="fa fa-female"></i>',
    rule: (can) => can('read', 'Person')
  }, {
    title: 'Subscriptions',
    to: '/subscriptions',
    htmlBefore: '<i class="fa fa-phone-volume"></i>',
    rule: (can) => can('read', 'Subscription')
  }, {
    title: 'Zones',
    to: '/zones',
    htmlBefore: '<i class="fa fa-map-marker-alt"></i>',
    rule: (can) => can('update', 'Zone')
  }, {
    title: 'Users',
    to: '/users',
    htmlBefore: '<i class="fa fa-user"></i>',
    rule: (can) => can('read', 'User')
  }, {
    title: 'API Keys',
    to: '/keys',
    htmlBefore: '<i class="fa fa-key"></i>',
    rule: (can) => can('read', 'User')
  }]
}, {
  title: 'Operations',
  items: [{
    title: 'Tag Scratch List',
    to: '/operations/tags',
    htmlBefore: '<i class="fa fa-box-open"></i>',
    htmlAfter: '',
    rule: (can) => can('manage', 'Tag')
  }, {
    title: 'Gateway Scratch List',
    to: '/operations/gateways',
    htmlBefore: '<i class="fa fa-dolly"></i>',
    htmlAfter: '',
    rule: (can) => can('manage', 'Tag')
  }]
}, {
  title: 'Reports',
  // action: {
  //   title: 'Edit',
  //   to: '/reports',
  //   rule: (can) => can('manage', 'Report')
  // },
  items: [{
    title: 'System Health',
    htmlBefore: '<i class="fa fa-tachometer-alt"></i>',
    rule: (can) => can('read', 'Metrics'),
    items: [{
      title: 'Diagnostics',
      to: '/reports/diagnostics'
    }]
  }, {
    title: 'Alerts',
    htmlBefore: '<i class="fa fa-clipboard"></i>',
    rule: (can) => can('read', 'Report'),
    items: [{
      title: 'Reasons',
      to: '/reports/alerts_reasons',
      htmlBefore: '<i class="fa fa-clipboard"></i>'
    }]
  }, {
    title: 'Tags',
    htmlBefore: '<i class="fa fa-rss"></i>',
    rule: (can) => can('manage', 'Tag'),
    items: [{
      title: 'Flagged Tags',
      to: '/reports/tags-flagged'
    }, {
      title: 'Missing Tags',
      to: '/reports/tags-not-seen'
    }]
  }, {
    title: 'Other',
    htmlBefore: '<i class="fa fa-clipboard"></i>',
    rule: (can) => can('read', 'Report'),
    items: []
  }]
}, {
  title: 'Logs',
  items: [{
    title: 'Sync Log',
    to: '/log/sync',
    htmlBefore: '<i class="fa fa-sync"></i>',
    rule: (can) => can('read', 'LogSync')
  }, {
    title: 'Change Log',
    to: '/log/audit',
    htmlBefore: '<i class="fa fa-clipboard-list"></i>',
    rule: (can) => can('read', 'LogAudit')
  }, {
    title: 'Batch Operations Log',
    to: '/log/batch',
    htmlBefore: '<i class="fa fa-list"></i>',
    htmlAfter: '',
    rule: (can) => can('read', 'LogBatch')
  }]
}]
