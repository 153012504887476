<template>
  <d-modal v-if="showModal" @close="close" disabled class="edit-modal">
    <d-modal-header v-if="title">
      <d-modal-title>{{ title }}</d-modal-title>
    </d-modal-header>
    <d-modal-body>
      <!-- Form Fields -->
      <d-form @keydown.enter.stop.prevent @submit.stop.prevent="save">
        <div class="form-group" v-for="field in valueFields" :key="field.id">
          <qrcode-stream v-if="field.qr && showQr" :ref="`qr-${field.id}`" @detect="onQRDetect($event, field.id)" @init="onQRInit($event, field.id)" />
          <label :for="field.id">{{ field.name }}</label>
          <span v-if="field.string">{{ field.string }}</span>
          <component v-else-if="field.component" :is="field.component" :value="field.value" :options="field.options" @change="field.value = $event" />
          <textarea v-else-if="field.text" class="form-control" :id="field.id"  v-model="field.value" :rows="3" />
          <suggest v-else-if="field.list" v-model="field.value"
            :ref="field.id"
            :list="field.list" :freeform="field.freeform" :regex="field.regex"
            :regexTruncateLength="field.regexTruncateLength"
            @change="field.value = $event" :qr="field.qr" @qr="showQr = !showQr" />
          <d-select v-else-if="field.options" v-model="field.value" :options="field.options" :disabled="field.disabled" />
          <zone-select v-else-if="field.type === 'location'" :editable="field.editable" :from="field.from" :noBranches="!!field.from" :value="field.value" @change="field.value = $event" :maxLevel="field.maxLevel" />
          <phrase-generator v-else-if="field.type === 'generator'" v-model="field.value" :label="field.name" :disabled="field.disabled" @change="field.value = $event" />
          <d-input v-else-if="field.id"
            :id="field.id"
            :ref="field.id"
            v-model="field.value"
            :type="field.type || 'text'"
            :placeholder="field.placeholder"
            :disabled="field.disabled"
          />
        </div>
        <template v-if="toggleFields.length">
          <label>Settings</label>
          <toggle v-for="field in toggleFields" :key="field.id" v-model="field.value" :label="field.name" />
        </template>
        <div class="form-group mb-3 d-table mx-auto">
        </div>
        <div v-if="submitting" class="d-table mx-auto edit-buttons text-right">
          <d-button disabled theme="light">
            <i class="fas fa-sync fa-spin mr-1"></i> Please wait...
          </d-button>
        </div>
        <div v-else class="d-table mx-auto edit-buttons text-right">
          <d-button @click.stop.prevent="cancel" class="mr-4" theme="light">
            {{ 'Cancel' }}
          </d-button>
          <d-button type="submit">
            {{ label || 'Submit' }}
          </d-button>
        </div>
      </d-form>
    </d-modal-body>
  </d-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { QrcodeStream } from '@vpulim/vue-qrcode-reader'

export default {
  name: 'edit-modal',
  props: [],
  data () {
    return {
      key: String(Math.random()),
      showModal: false,
      title: null,
      label: null,
      fields: [],
      showQr: false,
      submitting: false,
      onSubmit: () => {},
      onCancel: () => {},
      onClose: () => {}
    }
  },
  components: {
    QrcodeStream
  },
  computed: {
    ...mapGetters('zones', [
      'namedPath',
      'locationFor'
    ]),
    valueFields () {
      return this.fields.filter(f => f.type !== 'toggle')
    },
    toggleFields () {
      return this.fields.filter(f => f.type === 'toggle')
    }
  },
  methods: {
    open ({ title, label, fields, onSubmit, onClose, onCancel }) {
      this.title = title
      this.label = label
      this.fields = fields
      this.onSubmit = onSubmit || this.onSubmit
      this.onClose = onClose || this.onClose
      this.onCancel = onCancel || this.onCancel
      this.key = String(Math.random())
      this.showModal = true
      this.$nextTick(() => {
        this.fields.forEach(field => {
          if (field.focused) {
            const input = (this.$refs[field.id] || [])[0]
            if (input) {
              input.focus()
            }
          }
        })
      })
    },
    close () {
      if (this.showModal) {
        this.fields = []
        this.showModal = false
        this.onClose()
      }
    },
    save () {
      try {
        const values = {}
        this.fields.forEach(f => { values[f.id] = f.value })
        const result = this.onSubmit(values)
        if (result && typeof result.then === 'function') {
          this.submitting = true
          result
            .then(() => this.close())
            .catch((err) => alert(err.message))
            .finally(() => { this.submitting = false })
        } else {
          this.close()
        }
      } catch (err) {
        alert(err.message)
      }
    },
    cancel () {
      this.onCancel()
      this.close()
    },
    async onQRDetect (promise, id) {
      try {
        let { content: value } = await promise
        value = ((value || '').match(/([a-fA-F0-9]{12}$)/) || [])[0]
        const index = this.fields.findIndex(f => f.id === id)
        const field = this.fields[index]
        field.value = value
        if (index !== -1 && value) {
          this.$set(this.fields, index, { ...field, value: value.toLowerCase() })
        }
      } catch (error) {
        alert(`QR Scanner Error: ${error.name}`)
      }
    },
    async onQRInit (promise, id) {
      try {
        await promise
        const qr = this.$refs[`qr-${id}`]
        qr.height = qr.width
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          alert('You need to grant camera access permisson')
        } else if (error.name === 'NotFoundError') {
          alert('No camera on this device')
        } else if (error.name === 'NotSupportedError') {
          alert('Secure context required (HTTPS, localhost)')
        } else if (error.name === 'NotReadableError') {
          alert('The camera is already in use')
        } else if (error.name === 'OverconstrainedError') {
          alert('No suitable camera found')
        } else if (error.name === 'StreamApiNotSupportedError') {
          alert('This browser does not support QR Scanning')
        }
      }
    }
  }
}
</script>

<style>
.edit-modal .modal-content {
  background-color: #ffffffff;
}
.edit-modal.modal {
  z-index: 5000;
}
.edit-buttons {
  table-layout: fixed;
  width: 100%;
}
.qrcode-stream-wrapper {
  margin-bottom: 20px;
  height: 200px !important;
}
</style>
