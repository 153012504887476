import Auth from './auth'
import config from '@/config'

export default class GoogleAuth extends Auth {
  method () {
    return 'google'
  }

  login () {
    window.location = `${config.api.server}${config.api.path}/auth/google/${this.slug()}`
    return new Promise(resolve => {})
  }
}
