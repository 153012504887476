import Auth from './auth'
import config from '@/config'

export default class SamlAuth extends Auth {
  method () {
    return 'saml'
  }

  login () {
    window.location = `${config.api.server}${config.api.path}/auth/saml/${this.slug()}`
    return new Promise(resolve => {})
  }
}
