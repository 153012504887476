import Auth from './auth'
import config from '@/config'
import axios from 'axios'

export default class PasswordAuth extends Auth {
  method () {
    return 'password'
  }

  async login ({ email, password }) {
    const { data } = await axios.post(`${config.api.server}${config.api.path}/auth/password/${this.slug()}`, {
      username: email,
      password
    })
    return data
  }
}
