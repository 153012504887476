import api from '@/lib/api'
import * as idb from 'idb-keyval'

let gatewayIDBStore = null

// initial state
const state = {
  gateways: [],
  permittedOperations: [],
  loading: false,
  cacheLoaded: false
}

// Use to efficiently ensure uniqueness of gateways in state above.
// Vue reactivity is lacking with Map / Set object directly in the state
const gatewayIds = new Set()

// getters
const getters = {
  gateways: state => state.gateways,
  isOperationPermitted: state => op => state.permittedOperations.includes(op),
  permittedOperations: state => state.permittedOperations,
  preassignOrg: state => state.preassignOrg,
  loading: state => state.loading
}

// actions
const actions = {
  add: async ({ commit, state }, { gateways, pending = false }) => {
    commit('SET_LOADING', true)
    const oldCount = state.gateways.length
    commit('UPDATE_TAGS', gateways)
    const added = state.gateways.length - oldCount
    const ops = []
    if (gateways.every(t => ((t.config || {}).location || []).length >= 2 && t.config.location[1].slug === 'strongline-inventory')) {
      ops.push('transfer')
    }
    commit('UPDATE_OPS', ops)
    if (!pending) {
      commit('SET_LOADING', false)
    }
    return { added }
  },
  refresh: async ({ commit, state, dispatch }, gateways = null) => {
    const fromIDB = !!gateways
    gateways = gateways || state.gateways.map(t => t.id)
    // We don't have to clear IDB if the data was loaded from IDB and passed in
    commit('CLEAR_ALL', !fromIDB)
    commit('SET_LOADING', true)
    // Split total into 500 records apiece and fetch chunk by chunk
    const chunks = Math.ceil(gateways.length / 500)
    for (let i = 0; i < chunks; ++i) {
      const query = { id: { $in: gateways.slice(i * 500, (i + 1) * 500) } }
      const { data } = await api.get(`gateways?query=${JSON.stringify(query)}&locationFields=name%20level%20slug&orderBy=config.logistics.barcode&limit=500`)
      if (data && data.data && data.data.length) {
        await dispatch('add', { gateways: data.data, pending: true })
      }
    }
    commit('SET_LOADING', false)
  },
  clear: async ({ commit, state }) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ALL')
    commit('SET_LOADING', false)
  },
  truncate: async ({ commit, state }, newLength) => {
    commit('SET_LOADING', true)
    commit('TRUNCATE_TAGS', newLength)
    if (!state.gateways.length) {
      commit('CLEAR_ALL')
    }
    commit('SET_LOADING', false)
  },
  load: async ({ commit, dispatch, rootGetters }, reload) => {
    if (reload) {
      commit('SET_CACHE_LOADED', false)
    }
    if (state.cacheLoaded) {
      return
    }
    if (!gatewayIDBStore) {
      // Limitation of IDB: Can't currently create 2 tables in the same DB :|
      gatewayIDBStore = idb.createStore('strongline-scratch-2', 'gateway-list')
    }
    commit('SET_LOADING', true)
    const gateways = await idb.keys(gatewayIDBStore)
    if (gateways && gateways.length) {
      // Let's clear IDB right away and wait for completion since refresh will repopulate IDB asynchronously
      await idb.clear(gatewayIDBStore)
      // Call refresh to pull latest data and populate this store
      await dispatch('refresh', gateways)
    } else {
      commit('CLEAR_ALL', false)
      commit('SET_LOADING', false)
    }
    commit('SET_CACHE_LOADED', true)
  }
}

// mutations
const mutations = {
  CLEAR_ALL (state, clearIDB = true) {
    state.gateways = []
    gatewayIds.clear()
    state.permittedOperations = []
    state.preassignOrg = null
    if (clearIDB) {
      idb.clear(gatewayIDBStore)
    }
  },
  UPDATE_TAGS (state, gateways) {
    gateways = gateways.filter(t => {
      if (!gatewayIds.has(t.id)) {
        gatewayIds.add(t.id)
        return true
      }
      return false
    })
    state.gateways = state.gateways.concat(gateways)
    idb.setMany(gateways.map(t => [t.id, null]), gatewayIDBStore)
  },
  TRUNCATE_TAGS (state, newLength) {
    const removeCount = state.gateways.length - newLength
    if (removeCount > 0) {
      const removed = state.gateways.splice(newLength, removeCount)
      removed.forEach(t => gatewayIds.delete(t.id))
      idb.delMany(removed.map(t => t.id), gatewayIDBStore)
    }
  },
  UPDATE_OPS (state, ops) {
    state.permittedOperations = ops
  },
  SET_PREASSIGN_ORG (state, org) {
    state.preassignOrg = org
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_CACHE_LOADED (state, loaded) {
    state.cacheLoaded = loaded
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
