export default {
  google: {
    apiKey: 'AIzaSyClZExpZ_TcAzGAxam8D_mbyBEcOb7WbqE',
    clientId: '901925604512-7j7cgpcfh67r8uvt7c1efhtsfipq041c.apps.googleusercontent.com'
  },
  twilio: {
    sid: 'AC7f2eb46aa682550c7d7de49f34ca431f',
    token: '28714e96f6778974deadc8a45b96dcb1',
    from: '+16175440260'
  },
  api: {
    server: process.env.VUE_APP_API || 'http://localhost:8000',
    path: '/v1',
    maxAuthRetries: 1
  },
  testing: !!process.env.VUE_APP_TESTING
}
