import api from '@/lib/api'

// initial state
const state = {
  id: null,
  stationData: null,
  loading: false,
  adminAppMode: null
}

// getters
const getters = {
  id: state => state.id,
  name: state => state.stationData && state.stationData.name,
  help: state => state.stationData && state.stationData.helpMessage,
  minimumInventory: state => (state.stationData && state.stationData.minimumInventory) || 0,
  adminAppMode: state => state.adminAppMode,
  loading: state => state.loading
}

// actions
const actions = {
  loadStation: async ({ commit, state, dispatch, rootState }, stationId) => {
    try {
      commit('SET_LOADING', true)
      const key = `${rootState.slug}:strongline:distro:id`
      const id = stationId || localStorage[key]
      console.log({ id }, 'station_load')
      if (!id) {
        throw new Error('No distribution station settings found')
      } else if (stationId) {
        localStorage[key] = stationId
      }
      if (id !== state.id) {
        commit('SET_STATION_ID', null)
        commit('SET_STATION_DATA', null)
      }
      const { data } = await api.get(`distribution-station/${id}`)
      if (data && data.name) {
        commit('SET_STATION_ID', id)
        commit('SET_STATION_DATA', data)
      } else {
        throw new Error('Invalid distribution station settings')
      }
    } catch (err) {
      console.warn(`${err} when loading distribution station data`)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  clearStation: ({ commit }) => {
    commit('SET_STATION_ID', null)
    commit('SET_STATION_DATA', null)
  },
  setAdminAppMode: ({ commit }, mode) => {
    commit('SET_ADMIN_APP_MODE', mode)
  }
}

// mutations
const mutations = {
  SET_STATION_ID (state, id) {
    console.log('Distro station id:', id)
    state.id = id
  },
  SET_STATION_DATA (state, data) {
    console.log('Distro station data:', data)
    state.stationData = data
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_ADMIN_APP_MODE (state, mode) {
    state.adminAppMode = mode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
