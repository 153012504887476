import uuidv4 from 'uuid/v4'
import api from '@/lib/api'
import store from '@/store'

export function consoleKey () {
  return `${store.getters.slug}:strongline:console`
}

export function consoleId () {
  const key = `${consoleKey()}:id`
  let id = localStorage[key]
  if (!id || id === 'null') {
    const params = new URLSearchParams(window.location.search)
    id = params.get('consoleId') ? params.get('consoleId') : uuidv4().split('-')[0]
    localStorage[key] = id
  }
  return id
}

export async function consoleConfig (id) {
  try {
    const { data } = await api.get(`consoles/${id || consoleId()}`)
    store.commit('UPDATE_CONSOLE_CONFIG', data.config || {})
    return data.config || {}
  } catch (error) {
    console.log(error)
    return {}
  }
}

export async function consoleState (id) {
  try {
    const { data } = await api.get(`consoles/${id || consoleId()}`)
    return data.state || {}
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function consoleShots (id, from, to) {
  try {
    const { data } = await api.get(`consoles/${id || consoleId()}/snapshots`, { params: { from, to } })
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function consoleShot (id, shotId) {
  try {
    const { data } = await api.get(`consoles/${id}/snapshots/${shotId}`)
    return data
  } catch (error) {
    console.log(error)
    return []
  }
}

export async function sendConsoleBeat () {
  try {
    const id = consoleId()
    const user = store.getters['auth/user']
    if (!user) return
    const beat = {
      href: window.location.toString(),
      user: user.name || '',
      pwa: store.getters['pwa/isPWA'] || false,
      updatedAt: Date.now()
    }
    return api.post(`consoles/${id}/heartbeats`, beat)
  } catch (error) {
    console.log(error)
  }
}
