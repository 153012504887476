<template>
  <aside :class="['main-sidebar', 'col-12', 'col-md-3', 'col-lg-2', 'px-0', sidebarVisible ? 'open' : '']">
      <div class="main-navbar">
        <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
          <a class="navbar-brand w-100 mr-0" href="#" style="line-height: 25px;">
            <div class="d-table m-auto">
              <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 150px;" src="@/assets/images/logo.png" alt="Strongline">
            </div>
          </a>
          <a class="toggle-sidebar d-sm-inline d-md-none d-lg-none" @click="handleToggleSidebar()">
            <i class="material-icons">&#xE5C4;</i>
          </a>
        </nav>
      </div>

      <!-- <form action="#" class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
        <div class="input-group input-group-seamless ml-3">
          <div class="input-group-prepend">
            <div class="input-group-text">
              <i class="fas fa-search"></i>
            </div>
          </div>
          <input class="navbar-search form-control" type="text" placeholder="Search for something..." aria-label="Search">
        </div>
      </form> -->

      <div v-if="items" class="nav-wrapper">
        <div v-for="(nav, navIdx) in items" :key="navIdx">
          <h6 v-if="nav.title" class="main-sidebar__nav-title">
            <d-container class="p-0">
              <d-row no-gutters>
                <d-col>{{ nav.title }}</d-col>
                <d-col class="d-flex justify-content-end">
                  <d-link v-if="nav.action" class="nav-action" :to="nav.action.to">{{ nav.action.title }}</d-link>
                </d-col>
              </d-row>
            </d-container>
          </h6>
          <d-nav v-if="typeof nav.items !== 'undefined' && nav.items.length" class="nav--no-borders flex-column">
            <li v-for="(item, navItemIdx) in nav.items" :key="navItemIdx" class="nav-item dropdown">
              <d-link
                :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']"
                :to="item.to" v-d-toggle="`snc-${navIdx}-${navItemIdx}`"
                :data-ref="item.to && `tab${item.to.replace(/\//g, '.')}`"
              >
                <div class="item-icon-wrapper" v-if="item.htmlBefore" v-html="item.htmlBefore" />
                <div class="item-icon-wrapper" v-else></div>
                <span v-if="item.title">{{ item.title }}</span>
                <div class="item-icon-wrapper" v-if="item.htmlAfter" v-html="item.htmlAfter" />
              </d-link>
              <d-collapse v-if="item.items && item.items.length" :id="`snc-${navIdx}-${navItemIdx}`" class="dropdown-menu dropdown-menu-small" accordion="sidebar-items-accordion">
                <d-dropdown-item v-for="(subItem, subItemIdx) in item.items" :key="subItemIdx" :href="subItem.href" :to="subItem.to" :data-ref="subItem.to && `tab${subItem.to.replace(/\//g, '.')}`" class="nav-action">
                  {{ subItem.title }}
                </d-dropdown-item>
              </d-collapse>
            </li>
          </d-nav>
        </div>
      </div>
  </aside>
</template>

<script>
import Event from '@/lib/event'

export default {
  name: 'main-sidebar',
  props: {
    /**
      * Whether to hide the logo text, or not.
      */
    hideLogoText: {
      type: Boolean,
      default: false
    },
    /**
     * The menu items.
     */
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      sidebarVisible: false
    }
  },
  created () {
    Event.$on('toggle-sidebar', this.handleToggleSidebar)
  },
  beforeDestroy () {
    Event.$off('toggle-sidebar')
  },
  methods: {
    handleToggleSidebar () {
      this.sidebarVisible = !this.sidebarVisible
    }
  }
}
</script>

<style lang="scss">
  .main-sidebar {
    .item-icon-wrapper {
      display: inline-block;
    }
    .dropdown-menu {
      display: block;
    }
  }
  a.nav-action {
    cursor: pointer;
  }
  a.nav-action:hover {
    text-decoration: none;
  }
</style>
