<template>
  <div>
  <span class="mr-1">
    <i class="far fa-calendar-alt fa-lg mr-1" />
    {{ label ? ` ${label} ` : '' }}
  </span>
  <v-md-date-range-picker
    :class="label ? '' : 'w-75'"
    :style="label ? 'width: 70%;' : ''"
    opens="right"
    @change="dateRangeChange"
    :presets="presets"
    :start-date="(defaultRange && defaultRange.start) || range.start"
    :end-date="(defaultRange && defaultRange.end) || range.end"
    :showActivatorLabel="false"
    :showActivatorBar="false">
    <d-form-input slot="input" placeholder="Select Date Range"
      :value="formattedDateRange">
    </d-form-input>
  </v-md-date-range-picker>
  </div>
</template>

<script>
import utils from '@/mixins/utils'
import moment from 'moment/src/moment'

export default {
  name: 'date-picker',
  props: ['name', 'label', 'extraPresets', 'replacementPresets', 'defaultRange', 'openEndedRange'],
  mixins: [utils],
  data () {
    return {
      range: {
        start: moment().subtract(1, 'days'),
        end: moment()
      }
    }
  },
  mounted () {
    const rangeSetting = this.loadSetting(`${this.name}.range`)
    if (rangeSetting) {
      const range = JSON.parse(rangeSetting)
      this.range.start = moment(range.start)
      this.range.end = this.openEndedRange ? moment() : moment(range.end)
    } else if (this.defaultRange) {
      this.range = this.defaultRange
    }
    this.dateRangeChange()
  },
  computed: {
    formattedDateRange () {
      const range = this.presets.find(p => p.range[0].isSame(this.range.start, 'day') && p.range[1].isSame(this.range.end, 'day'))
      return range ? range.label : `${this.range.start.format('L')} - ${this.range.end.format('L')}`
    },
    presets () {
      return this.replacementPresets
        ? this.replacementPresets
        : [{
          label: 'Last 24 Hours',
          range: [moment().subtract(1, 'day'), moment()]
        }, {
          label: 'Last 7 days',
          range: [moment().subtract(7, 'day'), moment()]
        }, {
          label: 'Last 30 days',
          range: [moment().subtract(30, 'day'), moment()]
        }, {
          label: 'This Week',
          range: this.getRange(0, 0, 'week')
        }, {
          label: 'Last Week',
          range: this.getRange(1, 1, 'week')
        }, {
          label: 'This Month',
          range: this.getRange(0, 0, 'month')
        }, {
          label: 'Last Month',
          range: this.getRange(1, 1, 'month')
        }, {
          label: 'This Year',
          range: this.getRange(0, 0, 'year')
        }, {
          label: 'Last Year',
          range: this.getRange(1, 1, 'year')
        },
        ...(this.extraPresets ? this.extraPresets : [])]
    }
  },
  methods: {
    dateRangeChange (range) {
      if (range) {
        const [start, end] = range
        if (start.isSame(end, 'day')) return
        this.range = { start, end }
      }
      if (this.range && this.range.start && (this.range.end || this.openEndedRange)) {
        this.saveSetting(`${this.name}.range`, JSON.stringify({
          start: this.range.start.toISOString(),
          ...(!this.openEndedRange && { end: this.range.end.toISOString() })
        }))
        this.$emit('dateRangeChanged', this.range)
      }
    },
    getRange (startOffset = 0, endOffset = 0, period = 'day') {
      return [
        moment().subtract(startOffset, period).startOf(period),
        moment().subtract(endOffset, period).endOf(period)
      ]
    }
  }
}
</script>
