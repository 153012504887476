<template>
  <d-modal v-if="showModal" @close="close" disabled class="info-modal">
    <d-modal-header v-if="title">
      <d-modal-title>{{ title }}</d-modal-title>
    </d-modal-header>
    <d-modal-body v-if="map" class="floorplan">
      <floor-plan
        :location="map.location"
        :key="map.key"
        :tracker="map.tracker"
      />
    </d-modal-body>
    <d-modal-body v-else>
      <d-list-group v-if="info">
        <d-list-group-item v-for="[key, value] of Object.entries(info)" :key="key">
          <div class="float-left"><strong>{{ key }}:</strong></div>
          <div class="float-right">{{ ' ' + value }}</div>
        </d-list-group-item>
      </d-list-group>
    </d-modal-body>
  </d-modal>
</template>

<script>
export default {
  name: 'info-modal',
  props: [],
  data () {
    return {
      showModal: false,
      title: null,
      info: null,
      map: null
    }
  },
  methods: {
    open ({ title, info, map }) {
      this.title = title
      this.info = info
      this.map = map
      this.showModal = true
    },
    close () {
      if (this.showModal) {
        this.info = null
        this.title = null
        this.map = null
        this.showModal = false
      }
    }
  }
}
</script>

<style>
.info-modal .modal-content {
  background-color: #ffffffff;
}
.floorplan {
  padding: 0px;
  width: 500px;
  height: 300px;
}
</style>
