<template>
  <l-map ref="map"
    :minZoom="1"
    :maxZoom="22"
    @ready="onMapLoaded"
    @dblclick="onMapDoubleTap"
    :style="height ? { height } : ''"
    :options="{
      zoomControl: false,
      attributionControl: false,
      fullscreenControl: showControls && {
        position: 'topright'
      }
    }"
  >
    <l-control>
      <edit-modal ref="editModal"/>
    </l-control>
    <l-control>
      <progress-modal :progress="progress" @cancel="progress = null" />
    </l-control>
    <l-control-info v-if="showControls && editable" position="topright"
      :visible="plan || legacyPlan"
      :isRefreshing="refreshing"
      @click:refresh="refreshMarkers"
      :labels="labels"
      @toggle:labels="labels = $event"
      @toggle:diagnostics="diag = $event"
    />
    <l-control-edit v-if="showControls && editable" ref="editControl" position="topright"
      :units="units"
      :visible="plan || legacyPlan"
      :mode="editMode"
      @change:mode="editMode = $event"
      @add:unit="unitAdd"
      @edit:unit="unitEdit"
      @update:unit="unitUpdate"
      @delete:unit="unitUpdate"
      @click:unit="onUnitTap"
    />
    <l-control-plan v-if="showControls && editable" position="topright"
      :mode="editMode"
      :apiKey="apiKey"
      :show="!!showPlan"
      @change:mode="onPlanToggle"
      @upload="upload"
      @move="move"
      @toggle:show="onShowToggle"
    />
    <l-control v-if="showControls && editPlanMode" position="topleft">
      <place-autocomplete-field
        placeholder="Enter an an address, zipcode, or location"
        :api-key="apiKey"
        @autocomplete-select="move"
      />
    </l-control>
    <l-control-floors v-else-if="showControls && floors && floors.length" position="topleft"
      :floors="floors"
      @change="onFloorChange"
    />
    <l-tile-layer v-if="showStreets" url="http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" :zIndex="1" :options="{maxZoom: 24}" key="streetTiles" />
    <l-tile-layer v-if="plan" v-bind="plan" ref="tileLayer" :zIndex="2" :options="{maxZoom: 24}" key="planTiles" />
    <l-distortable-image-overlay ref="editPlanLayer" v-if="editPlan" v-bind="editPlan" :key="key" :zIndex="2000"
      @dragstart="onPlanEdit"
      @edit="onPlanEdit"
    />
    <l-circle v-if="tracker && tracker.latlng && tracker.type === 'alert'"
      :lat-lng="tracker.latlng"
      :radius="4"
      :stroke="false"
      fillColor="red"
    />
    <l-marker v-for="marker in markers"
      :key="key + marker.id + editMarkersMode + labels"
      :lat-lng="marker.config.coordinates"
      :icon="markerIcon(marker)"
      :draggable="editMarkersMode"
      @click="onMarkerTap(marker, $event)"
      @dragstart="dragging = true"
      @dragend="dragging = false, markerMove(marker, ($event.target || {})._latlng || {})"
      @mouseover="hoverMarker = isGateway(marker) && marker"
      @mouseout="hoverMarker = false"
    >
      <l-tooltip :options="{ permanent: labels || (diag && diagDetailsGateway === marker.id) }" :key="marker.id + editMarkersMode + labels + diag + 'm'">
        <p class="font-weight-bold pb-0 mb-0" v-if="marker.config.location[levelDepth('area')]">{{ marker.config.location[levelDepth('area')].name }}</p>
        <p v-if="isGateway(marker)" class="pb-0 mb-0">{{ marker.id }}</p>
        <p v-if="isGateway(marker) && marker.config.assetId" class="pb-0 mb-0">{{ marker.config.assetId }}</p>
        <template v-if="!editMarkersMode && isGateway(marker)">
          <p v-if="isSafeMode(marker)" class="pb-0 mb-0">Safe Mode</p>
          <p v-else-if="marker.state.adminMode" class="pb-0 mb-0">Admin Mode</p>
          <p class="pb-0 mb-0">{{ hwLabel(marker) }}</p>
          <div v-if="diag && marker.state.diagnostics" >
            <div class="mt-0" v-html="diagnosticsSummary(marker)" />
            <div class="mt-1" v-if="diagDetailsGateway === marker.id">
              <div class="mt-0">
                <p class="font-weight-bold pb-0 mb-0">Button</p>
                <p class="pb-0 mb-0 mt-0">Last Diagnostic: {{ marker.state.diagnostics.button ? 'Pass' : 'Fail' }}</p>
                <p class="pb-0 mb-0" v-if="gatewayMetrics[marker.id]">24h aggregate: {{ `${gatewayMetrics[marker.id].button.percent} (${gatewayMetrics[marker.id].button.total} / ${gatewayMetrics[marker.id].button.count})` }}</p>
              </div>
              <div class="mt-1">
                <p class="font-weight-bold pb-0 mb-0">Coverage</p>
                <p class="pb-0 mb-0 mt-0">Last Diagnostic: {{ marker.state.diagnostics.coverage ? 'Pass' : 'Fail' }}</p>
                <p class="pb-0 mb-0" v-if="gatewayMetrics[marker.id]">24h aggregate: {{ `${gatewayMetrics[marker.id].coverage.percent} (${gatewayMetrics[marker.id].coverage.total} / ${gatewayMetrics[marker.id].coverage.count})` }}</p>
              </div>
              <div class="mt-1 mb-1">
                <p class="font-weight-bold pb-0 mb-0">Floor</p>
                <p class="pb-0 mb-0 mt-0">Last Diagnostic: {{ marker.state.diagnostics.floor ? 'Pass' : 'Fail' }}</p>
                <p class="pb-0 mb-0" v-if="gatewayMetrics[marker.id]">24h aggregate: {{ `${gatewayMetrics[marker.id].floor.percent} (${gatewayMetrics[marker.id].floor.total} / ${gatewayMetrics[marker.id].floor.count})` }}</p>
              </div>
              <div v-for="(n, i) of neighbors(marker).onFloor" :key="n.id" class="pb-0 mb-0">
                <p class="font-weight-bold mb-0 pb-0" v-if="i === 0">Last diagnostic on-floor</p>
                <p class="mb-0 pb-0">{{ n.id }}: {{ n.distance.toFixed(2) }}m</p>
              </div>
              <div v-for="(n, i) of neighbors(marker).offFloor" :key="n.id" class="pb-0 mb-0">
                <p class="font-weight-bold mb-0 pb-0 mt-1" v-if="i === 0">Last diagnostic off-floor</p>
                <p class="mb-0 pb-0">{{ n.id }}: {{ n.distance.toFixed(2) }}m</p>
              </div>
            </div>
          </div>
        </template>
        <p v-if="marker.config.notes" class="pb-0 mb-0">{{ marker.config.notes }}</p>
      </l-tooltip>
    </l-marker>
    <l-feature-group ref="toolbar">
      <l-popup :options="{closeButton:false, offset:[0,0]}">
        <d-button-toolbar>
          <d-button-group size="small">
            <d-button theme="light" @click="toolbarAction('edit')"><i class="fa fa-pencil-alt" /></d-button>
            <d-button theme="light" @click="toolbarAction('delete')"><i class="fa fa-trash-alt" /></d-button>
          </d-button-group>
        </d-button-toolbar>
      </l-popup>
    </l-feature-group>
    <template v-if="diag">
      <l-polyline v-for="n of neighbors(hoverMarker).onFloor"
        :key="n.id"
        :lat-lngs="n.line"
        dashArray="4 8"
        color="green" />
    </template>
  </l-map>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import api from '@/lib/api'
import utils from '@/mixins/utils'
import axios from 'axios'
import * as parse from 'xml-parser'
import {
  LMap,
  LMarker,
  LCircle,
  LPopup,
  LTooltip,
  LFeatureGroup,
  LTileLayer,
  LControl,
  LPolyline
} from 'vue2-leaflet'
import {
  LControlEdit,
  LControlPlan,
  LControlInfo,
  LControlFloors,
  LDistortableImageOverlay
} from '@/components/map'
import EditModal from '@/components/EditModal'
import ProgressModal from '@/components/ProgressModal'
import 'leaflet.awesome-markers'
import 'leaflet-fullscreen'
import config from '@/config'

const BROWNOUT_RESET_CODE = 9
const BUTTON_PERCENT_THRESHOLD = 0.90
const COVERAGE_PERCENT_THRESHOLD = 0.90
const FLOOR_PERCENT_THRESHOLD = 0.51

const L = window.L
const planUrlBase = 'https://storage.googleapis.com/strongline-public/tiles'

export default {
  name: 'floor-plan',
  mixins: [utils],
  props: {
    location: {
      type: Array
    },
    height: {
      type: String
    },
    showControls: {
      type: Boolean
    },
    showMarkers: {
      type: Boolean
    },
    tracker: {
      type: Object
    }
  },
  components: {
    LMap,
    LMarker,
    LCircle,
    LControl,
    LPolyline,
    LPopup,
    LTooltip,
    LFeatureGroup,
    LTileLayer,
    LControlEdit,
    LControlPlan,
    LControlInfo,
    LControlFloors,
    LDistortableImageOverlay,
    EditModal,
    ProgressModal
  },
  data () {
    return {
      key: 0,
      apiKey: config.google.apiKey,
      map: null,
      floorId: null,
      units: [],
      trackerMarker: null,
      markers: [],
      markersMap: {},
      actions: null,
      dragging: false,
      loading: false,
      refreshing: false,
      hoverMarker: false,
      bounds: null,
      imageLoaded: false,
      viewport: null,
      diag: false,
      diagDetailsGateway: null,
      editable: this.$can('update', 'FloorPlan'),
      editMode: null,
      toolbarTarget: null,
      toolbarOffset: 0,
      showStreets: false,
      plan: null,
      editPlan: null,
      showPlan: true,
      planEdited: false,
      labels: false,
      zoom: 20,
      gatewayMetrics: {},
      progress: null
    }
  },
  watch: {
    async location () {
      this.updatedLocation()
    },
    tracker () {
      this.updatedTracker()
    },
    async diag () {
      await this.fetchGatewayMetrics()
      await this.refreshMarkers()
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    ...mapGetters('zones', [
      'locationTo',
      'zones',
      'zone',
      'subzones'
    ]),
    editMarkersMode () {
      return this.editMode === 'markers'
    },
    editUnitsMode () {
      if (this.editMode === 'units.edit' || this.editMode === 'units.add') {
        return this.editMode.split('.')[1]
      }
      return ''
    },
    editPlanMode () {
      return this.editMode === 'plan'
    },
    floors () {
      const buildingId = this.zoneIdAtLevel(this.location, 'building')
      if (buildingId) {
        const floors = this.subzones(buildingId)
        if (Array.isArray(floors)) {
          return floors
            .filter(floor => floor.config && typeof floor.config.number === 'number')
            .sort((a, b) => a.config.number - b.config.number)
        }
      }
      return []
    }
  },
  created () {
    if (this.editable) {
      this.refreshInterval = setInterval(() => this.refreshMarkers(), 5000)
    }
  },
  destroyed () {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval)
    }
  },
  methods: {
    ...mapActions('zones', {
      addZone: 'add',
      updateZone: 'update',
      deleteZone: 'delete',
      loadPath: 'loadPath'
    }),
    async initialize () {
      await this.updatedLocation()
      await this.updatedTracker()
    },
    prepareToExit (next) {
      const editControl = this.$refs.editControl
      if (editControl) {
        editControl.prepareToExit(next)
      } else {
        next()
      }
    },
    async onMapLoaded (map) {
      map.doubleClickZoom.disable()
      this.map = map
      return this.initialize()
    },
    onMapDoubleTap (event) {
      if (this.editMarkersMode) {
        this.markerAdd(event.latlng)
      } else if (this.editMode === 'units') {
        this.$refs.editControl.toggleDrawMode()
      }
    },
    onPlanToggle (mode) {
      this.editMode = mode
      this.$nextTick(() => {
        if (mode === 'plan') {
          this.showPlanEditor()
        } else {
          this.hidePlanEditor()
        }
      })
    },
    onShowToggle (show) {
      const tileLayer = this.$refs.tileLayer && this.$refs.tileLayer.mapObject
      if (show) {
        tileLayer && tileLayer.addTo(this.map)
      } else {
        tileLayer && tileLayer.remove()
      }
      this.showPlan = show
    },
    onFloorChange (floor) {
      this.updatedFloor(floor.id)
    },
    showPlanEditor (id) {
      if (!this.editPlanMode || !this.floorId) {
        this.editPlan = null
        return
      }
      this.showStreets = true
      this.planEdited = false
      let corners
      if (!id) {
        const floor = this.zone(this.floorId)
        const geometry = floor.config.geometry
        id = floor.config.planId
        if (geometry && geometry.coordinates) {
          corners = geometry.coordinates[0].slice(0, 4).map(([lng, lat]) => ({ lat, lng }))
          corners = [corners[0], corners[1], corners[3], corners[2]]
        }
      }
      if (!id) {
        this.editPlan = null
        return
      }
      this.key++
      this.editPlan = {
        id,
        url: `${planUrlBase}/${this.floorId}/${id}/thumbnail.jpg`,
        selected: true,
        editable: true,
        suppressToolbar: false,
        ...(corners && { corners })
      }
      this.onShowToggle(false)
    },
    async hidePlanEditor () {
      this.showStreets = false
      if (this.planEdited) {
        await this.generateTiles()
        this.planEdited = false
      }
      this.$nextTick(() => {
        this.editPlan = null
      })
      this.onShowToggle(true)
    },
    async updatePlan () {
      const zone = this.zone(this.floorId)
      if (!zone || !zone.config || !zone.config.planId || zone.level !== 'floor') {
        this.plan = null
        return
      }

      // try loading plan
      try {
        const planUrl = `${planUrlBase}/${this.floorId}/${zone.config.planId}`
        const xmlUrl = planUrl + '/tilemapresource.xml'
        const { data: metadata } = await axios.get(xmlUrl)
        const meta = parse(metadata)
        const { minx, miny, maxx, maxy, flipy } = meta.root.children.find(c => c.name === 'BoundingBox').attributes
        const tmsUrl = planUrl + (flipy === 'true' ? '/{z}/{x}/{y}.png' : '/{z}/{x}/{-y}.png')
        this.bounds = L.latLngBounds()
          .extend([Number(miny), Number(minx)]).extend([Number(maxy), Number(maxx)])
        this.updatedBounds()
        this.plan = {
          url: tmsUrl,
          tileSize: 256,
          options: { minZoom: 18, maxZoom: 22 }
        }
      } catch (error) {
        this.plan = null
        if (error.response && error.response.status !== 404) {
          console.log(error)
        }
      }
    },
    async updatedLocation () {
      await this.loadPath({ zone: this.zoneId(this.location) })
      let floorId = this.zoneIdAtLevel(this.location, 'floor')
      if (!floorId && this.floors.length) {
        floorId = this.floors[0].id
      }
      if (floorId !== this.floorId) {
        return this.updatedFloor(floorId)
      }
    },
    async updatedFloor (floorId) {
      if (!this.map) return
      if (floorId !== this.floorId) {
        this.key++
      }
      this.floorId = floorId
      try {
        this.loading = true
        if (this.floorId) {
          await this.loadPath({ zone: this.floorId })
          this.units = this.subzones(this.floorId)
        } else {
          this.units = []
        }
        await this.clearMarkers()
        await this.updatePlan()
        await this.fetchGatewayMetrics()
        await this.refreshMarkers()
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    updatedBounds (options = {}) {
      if (!this.map) return
      if (this.tracker && this.tracker.latlng) {
        this.map.flyTo(this.tracker.latlng, this.tracker.zoom || 21, options)
      } else {
        this.fit(this.bounds, { duration: 0 })
      }
    },
    updatedTracker () {
      this.updatedBounds({ animate: true })
    },
    async clearMarkers () {
      this.markers = []
      this.markersMap = {}
    },
    async refreshMarkers () {
      if (this.dragging || this.refreshing || !this.showMarkers) return
      if (!this.floorId) {
        this.clearMarkers()
        return
      }
      this.refreshing = true
      try {
        const { data: gateways } = await api.get('gateways', {
          params: {
            limit: 500,
            'config.state': { $in: ['deployed', 'ready_to_deploy'] },
            'config.location': this.floorId,
            'config.coordinates.lat': { $exists: true },
            'config.coordinates.lng': { $exists: true },
            'config.coordinates.alt': { $exists: true }
          }
        })
        const refreshed = gateways.data
        const { data: markers } = await api.get('markers', {
          params: {
            limit: 500,
            'config.location': this.floorId
          }
        })
        refreshed.push(...markers.data.filter(m => m.config.coordinates))
        this.markers = refreshed
        this.markersMap = {}
        refreshed.forEach(marker => { this.markersMap[marker.id] = marker })
      } catch (error) {
        console.log(error)
      } finally {
        this.refreshing = false
      }
    },
    updateMarker (marker) {
      const index = this.markers.findIndex(m => m.id === marker.id)
      if (index !== -1) {
        this.$set(this.markers, index, marker)
      }
    },
    isMarker (marker) {
      return marker && marker.state === undefined
    },
    isGateway (marker) {
      return marker && marker.state !== undefined
    },
    markerAddWithLocation ({ coordinates, location }, showLoader) {
      if (showLoader) this.loading = true
      return api.post('markers', { coordinates, location })
        .then(async ({ data }) => {
          await this.refreshMarkers()
          return data
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not add marker: ${error.reason || error.message}`)
        })
        .finally(() => { if (showLoader) this.loading = false })
    },
    async unitAdd (geometry) {
      const unit = await this.addZone({
        name: this.randomName('unit'),
        parent: this.floorId,
        level: 'unit',
        config: { geometry }
      })
      this.units.push(unit)
      return this.refreshMarkers()
    },
    async unitUpdate ({ id, geometry = null }) {
      const unit = await this.updateZone({
        id,
        config: { geometry }
      })
      const index = this.units.findIndex(u => u.id === id)
      this.$set(this.units, index, unit)
      return this.refreshMarkers()
    },
    edit (options) {
      if (this.map && this.map.isFullscreen()) {
        this.$refs.editModal.open(options)
      } else {
        this.editModal(options)
      }
    },
    async unitEdit (id) {
      const unit = this.zone(id)
      this.edit({
        title: 'Edit Unit',
        fields: [
          { id: 'name', name: 'Name', placeholder: 'Enter name of unit', value: unit.name }
        ],
        onSubmit: async ({ name }) => {
          if (!name) {
            throw new Error('Must specify a name')
          }
          try {
            await this.updateZone({ id, name })
            await this.refreshMarkers()
          } catch (error) {
            throw new Error(`Sorry, could not edit unit: ${error.reason || error.message}`)
          }
        }
      })
    },
    async markerAdd ({ lat, lng, location }, { showLoader } = {}) {
      if (!this.editable) return
      const coordinates = { lat, lng }
      location = this.locationById(location) || this.locationTo(this.floorId)
      await this.markerAddWithLocation({ coordinates, location }, showLoader)
    },
    async zoneCheck (unitId, area, areaName) {
      if (area) {
        if (area.parent === unitId) {
          if (area.name !== areaName) {
            await this.updateZone({ id: area.id, name: areaName })
          }
        } else {
          await this.updateZone({ id: area.id, name: areaName, parent: unitId })
        }
      } else {
        area = await this.addZone({ name: areaName, parent: unitId })
      }
      await this.loadPath({ zone: area.id })
      return this.locationTo(area.id)
    },
    async markerEdit (marker) {
      if (!this.editable) return
      try {
        if (this.isGateway(marker)) {
          return this.gatewayEdit(marker)
        }
        if (Array.isArray(marker.config.location)) {
          await this.loadPath({ zone: marker.config.location.slice(-1)[0].id })
        }
        const currentUnit = marker.config.location[this.levelDepth('unit')]
        const currentArea = marker.config.location[this.levelDepth('area')]
        const outletEnabled = (marker.config.location.slice(-1)[0].config || {}).outlet !== false
        this.edit({
          title: 'Assign Gateway',
          fields: [
            { id: 'gateway', name: 'MAC Address', placeholder: 'Enter or scan MAC address', list: (query) => this.assignableGateways(query), freeform: true, qr: true, regex: this.macRegEx, regexTruncateLength: this.macLength, focused: true },
            { id: 'unit', name: 'Unit', value: currentUnit && currentUnit.name, disabled: true },
            { id: 'area', name: 'Area', placeholder: 'Enter area name', value: currentArea && currentArea.name },
            { id: 'notes', name: 'Notes', placeholder: 'Enter notes', value: marker.config.notes },
            { id: 'outlet', name: 'Enable Power Outlet', value: outletEnabled, type: 'toggle' }
          ],
          onSubmit: async ({ gateway, area, notes, outlet }) => {
            if (!area) {
              throw new Error('Must specify area')
            }
            if (gateway && gateway.id) {
              // handle suggest result which is of form { id, title }
              gateway = gateway.id
            }
            if (gateway) {
              gateway = gateway.toLowerCase()
            }
            try {
              if (currentArea.name !== area || outletEnabled !== outlet) {
                await this.updateZone({ id: currentArea.id, name: area, config: { outlet } })
              }
              const changes = {
                location: this.locationById(marker.config.location),
                coordinates: {
                  ...marker.config.coordinates
                },
                notes
              }
              await this.markerUpdate(marker, changes)
              if (gateway) {
                const { data: existing } = await api.get(`gateways/${gateway}`)
                if (existing && existing.config && existing.config.coordinates) {
                  alert('This gateway is already assigned to another location')
                  return
                }
                await api.post(`markers/${marker.id}/assign/${gateway}`)
              }
              return this.refreshMarkers()
            } catch (error) {
              throw new Error(`Sorry, could not edit marker: ${error.reason || error.message}`)
            }
          }
        })
      } catch (error) {
        console.log(error)
        alert(`Sorry, could not edit marker: ${error.reason || error.message}`)
      }
    },
    async gatewayEdit (gateway) {
      const options = [
        { text: 'No action' },
        { value: 'uninstall', text: 'Uninstall' },
        { value: 'factory-reset', text: 'Uninstall & Factory Reset' },
        { value: 'rma', text: 'Uninstall & RMA' },
        { value: 'lost', text: 'Uninstall & Mark as Lost' }
      ]
      const unit = gateway.config.location[this.levelDepth('unit')]
      const currentArea = gateway.config.location[this.levelDepth('area')]
      const outletEnabled = (gateway.config.location.slice(-1)[0].config || {}).outlet !== false
      this.edit({
        title: 'Edit Gateway',
        fields: [
          { id: 'assetId', name: 'Asset ID', placeholder: gateway.config.assetId, disabled: true },
          { id: 'id', name: 'MAC Address', placeholder: gateway.id, disabled: true },
          { id: 'unit', name: 'Unit', value: unit && unit.name, disable: true },
          { id: 'area', name: 'Area', value: currentArea && currentArea.name },
          { id: 'action', name: 'Actions', options },
          { id: 'notes', name: 'Notes', value: gateway.config.notes, placeholder: 'Enter notes' },
          { id: 'outlet', name: 'Enable Power Outlet', value: outletEnabled, type: 'toggle' }
        ],
        onSubmit: async ({ action, notes, area, outlet }) => {
          if (!area) {
            throw new Error('Must specify unit and area')
          }
          const changes = {
            location: this.locationById(gateway.config.location),
            notes
          }
          if (area !== currentArea.name || outletEnabled !== outlet) {
            await this.updateZone({ id: currentArea.id, name: area, config: { outlet } })
          }
          await this.gatewayUpdate(gateway, changes)
          if (action) {
            await api.post(`gateways/${gateway.id}/unassign`, { action, rmaReason: '' })
          }
          return this.refreshMarkers()
        }
      })
    },
    gatewayUpdate (gateway, changes) {
      return api.put(`gateways/${gateway.id}`, changes)
        .then(() => {
          if ('coordinates' in changes && !changes.coordinates) {
            return this.markerAdd({
              ...gateway.config.coordinates,
              location: gateway.config.location
            })
          } else {
            return this.refreshMarkers()
          }
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not edit gateway: ${error.reason || error.message}`)
        })
    },
    markerUpdate (marker, changes) {
      return api.put(`markers/${marker.id}`, changes)
        .then(() => {
          return this.refreshMarkers()
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not edit marker: ${error.reason || error.message}`)
        })
    },
    markerDelete (marker, showLoader) {
      if (showLoader) this.loading = true
      return api.delete(`markers/${marker.id}`)
        .then(() => {
          return this.refreshMarkers()
        })
        .catch((error) => {
          console.log(error) // eslint-disable-line no-console
          alert(`Sorry, could not remove marker: ${error.reason || error.message}`)
        })
        .finally(() => { if (showLoader) this.loading = false })
    },
    async markerMove (marker, { lat, lng }) {
      if (!this.editMarkersMode || !lng || !lat) return
      const changes = {
        coordinates: { lat, lng },
        location: this.locationById(marker.config.location) || this.locationTo(this.floorId)
      }
      const prompt = 'Are you sure you want to move this gateway?'
      if (this.isGateway(marker)) {
        if (confirm(prompt)) {
          await this.gatewayUpdate(marker, changes)
        }
      } else {
        await this.markerUpdate(marker, changes)
      }
    },
    markerIcon (marker) {
      let icon = ''
      let markerColor = this.editMarkersMode ? 'blue' : 'gray'
      if (this.isGateway(marker)) {
        const status = marker.state && marker.state.status
        icon = 'microchip'
        if (!this.$can('read', 'Gateway', 'state.status')) {
          markerColor = 'blue'
        } else if (!this.editMarkersMode) {
          if (status === 'disconnected') markerColor = 'red'
          else if (this.hasPowerProblems(marker)) markerColor = 'pink'
          else if (status.startsWith('ota')) markerColor = 'blue'
          else if (this.isSafeMode(marker)) markerColor = 'darkblue'
          else if (marker.config.state === 'deployed') {
            if ((marker.state || {}).adminMode) {
              markerColor = 'darkgreen'
            } else {
              markerColor = this.checkDiagnostics(marker) ? 'green' : 'orange'
            }
          }
        }
      }
      return L.AwesomeMarkers.icon({ prefix: 'fa', icon, markerColor })
    },
    diagBadge (label) {
      if (label === 'Diagnostics OK') {
        return `<span class="p-1 mr-1 diag badge badge-pill badge-success">${label}</span>`
      } else {
        return `<span class="p-1 mr-1 diag badge badge-pill badge-danger">${label}</span>`
      }
    },
    diagnosticsSummary (marker) {
      let label = 'Diagnostics OK'
      if (marker.state.status === 'disconnected') {
        label = 'Gateway down'
      } else {
        const diagResult = this.checkDiagnostics(marker, true)
        if (!diagResult.button || !diagResult.coverage || !diagResult.floor) {
          label = diagResult.button && diagResult.coverage ? 'Floor failures' : 'Coverage failures'
        }
      }
      return `${this.diagBadge(label)}`
    },
    neighbors (marker) {
      const gateway = (this.isGateway(marker) && marker) || {}
      const { coordinates: start } = gateway.config || {}
      if (!start) return []
      const { diagnostics = {} } = gateway.state || {}
      const neighbors = Object.entries(diagnostics.neighbors || {})
      const onFloor = neighbors
        .filter(([id, distance]) => !!this.markersMap[id])
        .map(([id, distance]) => {
          const neighbor = this.markersMap[id] || {}
          const { coordinates, assetId } = neighbor.config || {}
          return coordinates && { id, assetId, distance, line: [start, coordinates] }
        })
      const offFloor = neighbors
        .filter(([id, distance]) => !this.markersMap[id])
        .map(([id, distance]) => ({ id, distance }))
      return { onFloor, offFloor }
    },
    isSafeMode (gateway) {
      return ((gateway.state || {}).version || {}).isSafeMode
    },
    hwLabel (gateway) {
      const version = {
        v2: '2',
        v3: '3'
      }[((gateway.state || {}).hardware || {}).revision] || '?'
      return `Version: ${version}`
    },
    hasPowerProblems (gateway) {
      return (((gateway.state || {}).debug || {}).rebootInfo || {}).lastResetReason === BROWNOUT_RESET_CODE || 0
    },
    async generateTiles () {
      try {
        if (!confirm('Save changes to floor plan?') || !this.editPlan) {
          return
        }
        const plan = this.$refs.editPlanLayer
        const floor = this.zone(this.floorId)
        const planId = floor && floor.config && floor.config.planId
        const { data } = await api.post(`/zones/${this.floorId}/plan/tile`, {
          ...(planId ? { sourceId: planId } : { id: this.editPlan.id }),
          corners: plan.getCorners()
        })
        const { job, id } = data
        let status = { isRunning: true }
        this.updateProgress({
          step: 'Saving Changes...',
          percent: 0
        })
        while (status.isRunning && this.progress) {
          const { data } = await api.get(`/zones/${this.floorId}/plan/tile/${job}`)
          status = data
          if ('percent' in status) {
            this.updateProgress({
              step: `Saving Changes: ${status.step}`,
              percent: status.percent
            })
          }
          if (status.isRunning) {
            await this.delay(1000)
          }
        }
        if (!this.progress) {
          return
        }
        if (status.failReason) {
          throw new Error(status.failReason)
        }
        if (this.progress.percent !== 100) {
          throw new Error('Prematurely stopped')
        }
        await this.updateZone({
          id: this.floorId,
          config: {
            planId: id,
            geometry: plan.getGeometry()
          }
        })
        await this.updatePlan()
      } catch (error) {
        console.log(error)
        this.updateProgress()
        alert('Sorry, there was a problem editing the floor plan. Please try again.')
      } finally {
        this.updateProgress()
      }
    },
    async upload ({ target }) {
      if (!this.floorId) return
      const { files } = target
      const formData = new FormData()
      files.forEach(file => formData.append('floorplan', file))
      try {
        this.updateProgress({
          step: 'Uploading image...',
          cancel: false
        })
        const { data } = await api.post(`/zones/${this.floorId}/plan/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 60000
        })
        this.showPlanEditor(data.id)
        this.planEdited = true
      } catch (error) {
        console.log(error)
        this.updateProgress()
        alert(`Sorry, there was a problem uploading: ${error.reason || error.message}`)
      } finally {
        target.value = ''
        this.updateProgress()
      }
    },
    move (place, geocoder) {
      if (!geocoder || !geocoder.geometry) return
      const { viewport } = geocoder.geometry
      const ne = viewport.getNorthEast()
      const sw = viewport.getSouthWest()
      this.fit([ne.toJSON(), sw.toJSON()])
    },
    fit (bounds, options) {
      if (bounds) {
        this.map.fitBounds(bounds, options)
      }
    },
    async assignableGateways (query) {
      if (query.length < 4) return []
      const params = {
        query: JSON.stringify({ _id: query, 'config.state': 'ready_to_deploy' }),
        limit: 20,
        byColumn: 1,
        orderBy: '_id'
      }
      const { data } = await api.get('gateways', { params })
      if (data && data.data && data.data.length) {
        return data.data.map(gateway => ({
          id: gateway.id,
          title: gateway.id
        }))
      }
      return []
    },
    onMarkerTap (marker, event) {
      if (this.editMarkersMode) {
        if (this.isMarker(marker)) {
          this.toolbarOpen(event.latlng, { type: 'marker', marker })
        } else {
          this.markerEdit(marker)
        }
      } else if (this.diag && this.isGateway(marker)) {
        this.diagDetailsGateway = marker.id
      }
    },
    onUnitTap ({ unit, latLng }) {
      if (this.editMarkersMode) {
        this.toolbarOpen(latLng, { type: 'unit', unit })
      }
    },
    toolbarOpen (coordinates, target, offset) {
      this.toolbarTarget = target
      this.toolbarOffset = offset || 0
      this.$refs.toolbar.mapObject.openPopup(coordinates)
    },
    toolbarClose () {
      this.$refs.toolbar.mapObject.closePopup()
      this.toolbarTarget = null
    },
    toolbarAction (action) {
      const target = this.toolbarTarget
      this.toolbarClose()
      const { type } = target
      if (type === 'marker') {
        const { marker } = target
        if (action === 'edit') {
          this.markerEdit(marker)
        } else if (action === 'delete') {
          this.markerDelete(marker)
        }
      } else if (type === 'unit') {
        const editor = this.$refs.unitEditor
        const { unit } = target
        editor.delete(unit.id)
      }
    },
    onPlanEdit () {
      this.planEdited = true
    },
    async fetchGatewayMetrics () {
      if (!this.showMarkers || !this.showControls) return
      this.refreshing = true
      let metrics = {}
      try {
        if (this.floorId) {
          const now = new Date()
          const start = new Date(now.getTime() - 24 * 3600 * 1000).toISOString()
          const { data } = await api.get(`metrics/gateways?zone=${this.floorId}&start=${start}&end=${now.toISOString()}`)
          metrics = data
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.gatewayMetrics = metrics
        this.refreshing = false
      }
    },
    checkDiagnostics (marker, fullResult) {
      const metrics = this.gatewayMetrics[marker.id]
      if (metrics) {
        const button = metrics.button.percent >= BUTTON_PERCENT_THRESHOLD
        const coverage = metrics.coverage.percent >= COVERAGE_PERCENT_THRESHOLD
        const floor = metrics.floor.percent >= FLOOR_PERCENT_THRESHOLD
        return fullResult ? { button, coverage, floor } : button && coverage && floor
      }
      const { button, coverage, floor } = (marker.state || {}).diagnostics || {}
      return fullResult ? { button, coverage, floor } : button && coverage && floor
    },
    updateProgress (progress) {
      this.progress = progress && { ...progress }
    }
  }
}
</script>

<style>
  .vue2leaflet-map {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: white;
  }
  .leaflet-popup-content {
    padding: 2px;
    margin: 0px;
  }
  .autocomplete-field {
    z-index: 1000;
    width: 400px;
  }
  .leaflet-top {
    z-index: 900;
  }
</style>

<style scoped>
  .marker {
    font-size: 36px;
  }
  .vue2leaflet-map {
    cursor: crosshair;
  }
  .refresh-button {
    z-index: 9999;
  }
</style>
