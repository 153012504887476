import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import alerts from './modules/alerts'
import zones from './modules/zones'
import provision from './modules/provision'
import distributionStation from './modules/distribution.station'
import pwa from './modules/pwa'
import tagBatchOps from './modules/tag.batch.ops'
import gatewayBatchOps from './modules/gateway.batch.ops'

Vue.use(Vuex)

const slugKey = `${window.location.hostname}:strongline:slug`

function getSlug () {
  const { hostname } = document.location
  const query = new URLSearchParams(window.location.search)
  const querySlug = query.get('slug')
  let slug = querySlug || hostname.split('.')[0]
  if (querySlug) {
    sessionStorage[slugKey] = querySlug
  } else if (slug === 'localhost' && sessionStorage[slugKey]) {
    slug = sessionStorage[slugKey]
  }
  return slug === 'localhost' ? 'admin' : slug
}

const state = {
  connected: true,
  muted: false,
  slug: getSlug(),
  consoleConfig: null
}

const getters = {
  connected: state => state.connected,
  muted: state => state.muted,
  slug: state => state.slug,
  consoleConfig: state => state.consoleConfig
}

const actions = {
  setMuted: async ({ commit }, isMuted) => {
    commit('SET_MUTED', isMuted)
  }
}

const mutations = {
  SET_CONNECTED (state, isConnected) {
    state.connected = isConnected
  },
  SET_MUTED (state, isMuted) {
    state.muted = isMuted
  },
  SET_SLUG (state, slug) {
    if (slug) {
      sessionStorage[slugKey] = slug
    } else {
      delete sessionStorage[slugKey]
      slug = getSlug()
    }
    state.slug = slug
  },
  UPDATE_CONSOLE_CONFIG (state, config) {
    state.consoleConfig = config
  }
}

export default new Vuex.Store({
  modules: {
    auth,
    zones,
    alerts,
    provision,
    distributionStation,
    pwa,
    tagBatchOps,
    gatewayBatchOps
  },
  state,
  getters,
  actions,
  mutations,
  strict: true
})
