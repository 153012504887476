import api from '@/lib/api'

const appMode = {
  singleTap: 'tap1',
  doubleTap: 'tap2',
  qrCode: 'qronly',
  off: 'manual'
}

// initial state
const state = {
  tag: null,
  tagData: null,
  metadata: null,
  loading: false,
  scanMode: null,
  buttonInfo: null
}

// getters
const getters = {
  tag: state => state.tag,
  config: state => state.tagData && state.tagData.config,
  metadata: state => state.metadata,
  loading: state => state.loading,
  scanMode: state => state.scanMode,
  buttonInfo: state => state.buttonInfo
}

const scanDelayMillis = 1000

function levelToStatus (level) {
  if (level >= 2800) {
    return 'Healthy'
  } else if (level >= 2700) {
    return 'Low'
  } else if (Number.isFinite(level)) {
    return 'Unhealthy'
  }
  return 'Unknown'
}

// actions
const actions = {
  fetchTagData: async ({ commit, state, dispatch }, { tag, btm }) => {
    try {
      const { data: tagData } = await api.get(`tags/${tag}`)
      if (tagData) {
        commit('SET_TAG_DATA', tagData)
      } else {
        throw new Error('Tag state not found')
      }
      dispatch('setTagMetadata', btm)
    } catch (err) {
      console.warn(`${err} when fetching state & processing tag battery data`)
    } finally {
      commit('SET_LOADING', false)
    }
  },
  saveButtonInfo ({ state, commit }, { buttonInfo }) {
    console.log(buttonInfo, 'button_info_received')
    commit('SET_BUTTON_INFO', buttonInfo)
  },
  setTag: ({ commit, state, dispatch }, { tag, btm, buttonInfo } = {}) => {
    if (!tag) return
    if (tag !== state.tag) {
      commit('SET_TAG_METADATA', null)
      commit('SET_TAG_DATA', null)
      commit('SET_BUTTON_INFO', null)
    }
    if (tag !== state.tag || !state.tagData || !state.metadata || !state.metadata.batteryHealth || !state.metadata.maxBatteryDate) {
      commit('SET_LOADING', true)
    }
    commit('SET_TAG', tag)
    dispatch('fetchTagData', { tag, btm })
      .then(() => {
        if (buttonInfo) {
          dispatch('saveButtonInfo', { buttonInfo })
            .catch((err) => {
              console.warn(`${err} when processing button info`)
            })
        }
      })
      .catch((err) => {
        console.warn(`${err} when fetching tag state`)
        commit('SET_LOADING', false)
      })
  },
  setTagMetadata: ({ commit, state }, tagMetadata) => {
    const currentMetadata = { ...(state.metadata || {}) }
    const tagState = state.tagData.state || {}
    if (tagState.firmware) {
      currentMetadata.blVersion = tagState.firmware.boot
      currentMetadata.appVersion = tagState.firmware.app
    }
    if (tagState.battery && tagState.battery.current) {
      currentMetadata.batteryMilliVolts = tagState.battery.current.level
      currentMetadata.batteryHealth = tagState.battery.current.status || levelToStatus(currentMetadata.batteryMilliVolts) || 'Unknown'
    }
    console.log(`Metdata: ${JSON.stringify(currentMetadata)}`)
    commit('SET_TAG_METADATA', currentMetadata)
  },
  clearTag: ({ commit }) => {
    commit('SET_TAG', null)
    commit('SET_TAG_METADATA', null)
  },
  setScanMode: ({ commit, state }, mode, delayScan) => {
    if (!appMode[mode]) {
      throw new Error(`Invalid tag scan mode: ${mode}`)
    }
    if (window.BadgeAndroidApp) {
      const badgeApp = window.BadgeAndroidApp
      badgeApp.setBadgeScanMode(appMode[mode], delayScan ? scanDelayMillis : 0)
    }
    commit('SET_SCAN_MODE', mode)
  },
  // NOTE: There is no resume. Setting the badge scan mode again will resume scanning
  pauseScan: () => {
    if (window.BadgeAndroidApp && window.BadgeAndroidApp.pauseBadgeScan) {
      const badgeApp = window.BadgeAndroidApp
      badgeApp.pauseBadgeScan()
    }
  }
}

// mutations
const mutations = {
  SET_TAG (state, tag) {
    console.log('Detected Tag:', tag)
    state.tag = tag
  },
  SET_TAG_DATA (state, tagData) {
    console.log('Fetched tag data')
    state.tagData = tagData
  },
  SET_TAG_METADATA (state, metadata) {
    console.log('Loaded Metadata:', metadata)
    state.metadata = metadata
  },
  SET_SCAN_MODE (state, mode) {
    console.log('Set scan mode:', mode)
    state.scanMode = mode
  },
  SET_BUTTON_INFO (state, buttonInfo) {
    state.buttonInfo = buttonInfo
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
