<template>
  <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
    <div :class="[contained ? 'container' : 'container-fluid']">
      <div class="row">
        <span class="copyright ml-2 my-auto"><small>{{ time }} {{ org ? `(${org})` : '' }}</small></span>
        <span class="copyright ml-auto my-auto mr-2"><small>{{ copyright }} ({{ consoleId }})</small></span>
      </div>
    </div>
  </footer>
</template>

<script>
import { consoleId } from '@/lib/console'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'main-footer',
  props: {
    /**
       * The copyright information.
       */
    copyright: {
      type: String,
      default: 'Copyright © 2019 SMP Labs, Inc.'
    },
    /**
       * Whether the footer should be wrapped in a container, or not.
       */
    contained: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      consoleId: null,
      org: null,
      time: ''
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    organization () {
      const zone = this.user && this.user.zone
      if (zone && zone.level === 'org') {
        return zone.name
      }
      return ''
    }
  },
  mounted () {
    this.consoleId = consoleId()
    this.interval = setInterval(() => this.updateTime(), 500)
    if (Array.isArray(this.user && this.user.location)) {
      const org = this.user.location.find(zone => zone.level === 'org')
      if (org) {
        this.org = org.name
      }
    }
  },
  destroyed () {
    clearInterval(this.interval)
  },
  methods: {
    updateTime () {
      this.time = moment.tz(new Date(), moment.tz.guess()).format('ddd, MMM Do, h:mm:ss a z')
    }
  }
}
</script>
