import api from '@/lib/api'
import * as idb from 'idb-keyval'
import { barcodeCompare } from '@/lib/util'

let stronglineIDBStore = null

// initial state
const state = {
  tags: [],
  permittedOperations: [],
  preassignOrg: null,
  loading: false,
  cacheLoaded: false
}

// Use to efficiently ensure uniqueness of tags in state above.
// Vue reactivity is lacking with Map / Set object directly in the state
const tagIds = new Set()

// getters
const getters = {
  tags: state => state.tags,
  isOperationPermitted: state => op => state.permittedOperations.includes(op),
  permittedOperations: state => state.permittedOperations,
  preassignOrg: state => state.preassignOrg,
  loading: state => state.loading
}

// actions
const actions = {
  add: async ({ commit, state }, { tags, pending = false }) => {
    commit('SET_LOADING', true)
    const oldCount = state.tags.length
    commit('UPDATE_TAGS', tags)
    const added = state.tags.length - oldCount
    const ops = []
    if (tags.every(t => (t.config || {}).state === 'available')) {
      ops.push('transfer')
      const orgs = new Map()
      tags.forEach(t =>
        ((t.config || {}).location || []).length === 2 &&
        orgs.set(t.config.location[1].id, t.config.location[1])
      )
      const org = orgs.size === 1 && Array.from(orgs.values())[0]
      // Preassignment is allowed, and the preassign org details are set if:
      // 1. The newly added tags are all from the same org, AND
      // 2. There are either no existing tags or the org of new tags & old tags is the same
      if (org && (!oldCount || (state.preassignOrg || {}).id === org.id)) {
        commit('SET_PREASSIGN_ORG', org)
        ops.push('preassign')
        ops.push('label')
      } else {
        commit('SET_PREASSIGN_ORG', null)
      }
    } else if (tags.every(t => (t.config || {}).state === 'reserved')) {
      ops.push('unreserve')
    }
    commit('UPDATE_OPS', ops)
    if (!pending) {
      commit('SET_LOADING', false)
    }
    return { added }
  },
  refresh: async ({ commit, state, dispatch }, tags = null) => {
    const fromIDB = !!tags
    tags = tags || state.tags.map(t => t.id)
    // We don't have to clear IDB if the data was loaded from IDB and passed in
    commit('CLEAR_ALL', !fromIDB)
    commit('SET_LOADING', true)
    // Split total into 500 records apiece and fetch chunk by chunk
    const chunks = Math.ceil(tags.length / 500)
    for (let i = 0; i < chunks; ++i) {
      const query = { id: { $in: tags.slice(i * 500, (i + 1) * 500) } }
      const { data } = await api.get(`tags?query=${JSON.stringify(query)}&ownerFields=name%20personId&orderBy=config.logistics.barcode&limit=500`)
      if (data && data.data && data.data.length) {
        await dispatch('add', { tags: data.data, pending: true })
      }
    }
    commit('SORT_TAGS')
    commit('SET_LOADING', false)
  },
  clear: async ({ commit, state }) => {
    commit('SET_LOADING', true)
    commit('CLEAR_ALL')
    commit('SET_LOADING', false)
  },
  truncate: async ({ commit, state }, newLength) => {
    commit('SET_LOADING', true)
    commit('TRUNCATE_TAGS', newLength)
    if (!state.tags.length) {
      commit('CLEAR_ALL')
    }
    commit('SET_LOADING', false)
  },
  load: async ({ commit, dispatch, rootGetters }, reload) => {
    if (reload) {
      commit('SET_CACHE_LOADED', false)
    }
    if (state.cacheLoaded) {
      return
    }
    if (!stronglineIDBStore) {
      stronglineIDBStore = idb.createStore('strongline-scratch', 'tag-list')
    }
    commit('SET_LOADING', true)
    const tags = await idb.keys(stronglineIDBStore)
    if (tags && tags.length) {
      // Let's clear IDB right away and wait for completion since refresh will repopulate IDB asynchronously
      await idb.clear(stronglineIDBStore)
      // Call refresh to pull latest data and populate this store
      await dispatch('refresh', tags)
    } else {
      commit('CLEAR_ALL', false)
      commit('SET_LOADING', false)
    }
    commit('SET_CACHE_LOADED', true)
  }
}

// mutations
const mutations = {
  CLEAR_ALL (state, clearIDB = true) {
    state.tags = []
    tagIds.clear()
    state.permittedOperations = []
    state.preassignOrg = null
    if (clearIDB) {
      idb.clear(stronglineIDBStore)
    }
  },
  UPDATE_TAGS (state, tags) {
    tags = tags.filter(t => {
      if (!tagIds.has(t.id)) {
        tagIds.add(t.id)
        return true
      }
      return false
    })
    state.tags = state.tags.concat(tags)
    idb.setMany(tags.map(t => [t.id, null]), stronglineIDBStore)
  },
  SORT_TAGS (state) {
    state.tags = state.tags.sort(barcodeCompare)
  },
  TRUNCATE_TAGS (state, newLength) {
    const removeCount = state.tags.length - newLength
    if (removeCount > 0) {
      const removed = state.tags.splice(newLength, removeCount)
      removed.forEach(t => tagIds.delete(t.id))
      idb.delMany(removed.map(t => t.id), stronglineIDBStore)
    }
  },
  UPDATE_OPS (state, ops) {
    state.permittedOperations = ops
  },
  SET_PREASSIGN_ORG (state, org) {
    state.preassignOrg = org
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  SET_CACHE_LOADED (state, loaded) {
    state.cacheLoaded = loaded
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
