<template>
  <component :is="layout">
    <router-view v-bind:key="$route.fullPath"></router-view>
  </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from 'axios'
import HeartBeats from '@/lib/heartbeats'

const hashCheckInterval = 60000
const alertRefreshInterval = 2000
const badgeApp = window.BadgeAndroidApp

export default {
  data () {
    return {
      hash: null,
      heartBeats: null,
      alertRefreshTimer: null
    }
  },
  computed: {
    ...mapGetters('auth', [
      'user'
    ]),
    layout () {
      if (!this.$route.name) {
        return 'loading-layout'
      }
      return `${this.$route.meta.layout || 'default'}-layout`
    }
  },
  watch: {
    user (user) {
      if (user) {
        this.heartBeats.start()
        if (this.alertRefreshTimer) {
          clearInterval(this.alertRefreshTimer)
        }
        if (this.$can('read', 'Alert') && !window.GatewayMobileApp) {
          this.alertRefreshTimer = setInterval(() => this.$store.dispatch('alerts/refresh'), alertRefreshInterval)
        }
      } else {
        this.heartBeats.stop()
        if (this.alertRefreshTimer) {
          clearInterval(this.alertRefreshTimer)
        }
        this.$store.dispatch('tagBatchOps/clear')
      }
    }
  },
  created () {
    const host = window.location.host
    const hostParts = host.split('.')

    if (hostParts.length === 4 || hostParts.length === 5) {
      document.title = 'Strongline: ' + hostParts[0]
    } else {
      document.title = 'Strongline'
    }
    this.heartBeats = new HeartBeats()
  },
  destroyed () {
    this.heartBeats.stop()
  },
  mounted () {
    if (['production', 'staging'].includes(process.env.NODE_ENV)) {
      setInterval(() => this.checkHash(), hashCheckInterval)
    }
    if (this.user) {
      this.heartBeats.start()
      if (this.$can('read', 'Alert') && !window.GatewayMobileApp) {
        if (this.alertRefreshTimer) {
          clearInterval(this.alertRefreshTimer)
        }
        this.alertRefreshTimer = setInterval(() => this.$store.dispatch('alerts/refresh'), alertRefreshInterval)
      }
    }
  },
  methods: {
    ...mapActions('provision', [
      'setTag'
    ]),
    async checkHash () {
      try {
        const { data: hash } = await axios.get(`/hash.json?t=${Date.now()}`, {
          responseType: 'text'
        })
        if (!this.hash) {
          this.hash = hash
        } else if (this.hash !== hash) {
          for (const savedKey of Object.keys(window.sessionStorage)) {
            if (savedKey.startsWith('vuetables')) {
              delete window.sessionStorage[savedKey]
            }
          }
          if (badgeApp && badgeApp.clearCacheAndForceReload) {
            badgeApp.clearCacheAndForceReload()
          } else {
            window.location.reload()
          }
        }
        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistration(`${process.env.BASE_URL}service-worker.js`)
            .then(registration => registration.update())
            .catch(error => console.log(error, 'service_worker_update_check_failed'))
        }
      } catch (err) {
        // console.log('Could not retrieve app hash:', err)
      }
    }
  }
}
</script>

<style>
span.nobreak {
  white-space: nowrap;
}
</style>
